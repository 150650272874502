import logo from './logo.svg';
import './App.css';
import Notification from './components/pushnotification';

function App() {
  return (
    <div>
      <Notification></Notification>
    </div>
  );
}

export default App;
