import React, {Component} from 'react';
import axios from 'axios';

export default class Pushnotification extends Component {
  constructor(props) {
    super(props);
    
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitApn = this.onSubmitApn.bind(this);
    this.onChangeApiKey = this.onChangeApiKey.bind(this);
    this.onChangeDeviceToken = this.onChangeDeviceToken.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangePemFile = this.onChangePemFile.bind(this);
    this.onChangePemText = this.onChangePemText.bind(this);
    this.onChangeBundleId = this.onChangeBundleId.bind(this);
    
    this.state = {
    	api_key: '',
      	device_token: '',
      	message: '',
      	pem_text: '',
      	pem_file: '',
      	bundle_id: '',
      	success_msg: '',
      	error_msg: '',
    }
  }

  onChangeApiKey(e) {
    this.setState({
      api_key: e.target.value
    });
  }
 
  onChangeDeviceToken(e) {
    this.setState({
      device_token: e.target.value
    });
  }

  onChangeMessage(e) {
    this.setState({
      message: e.target.value
    });
  }

  onChangePemFile(e) {
    this.setState({
      pem_file: e.target.files[0]
    });
  }

  onChangePemText(e) {
    this.setState({
      pem_text: e.target.value
    });
  }

  onChangeBundleId(e) {
    this.setState({
      bundle_id: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      error_msg: '',
      success_msg: ''
    });
    const formData = new FormData();
    formData.append('api_key',this.state.api_key);
    formData.append('device_token',this.state.device_token);
    formData.append('message',this.state.message);
    formData.append('submitFCM',true);
  
    axios.defaults.headers.common = {
      "Access-Control-Allow-Origin": "*",
	  'Content-Type' : 'application/json; charset=UTF-8',
	}
    axios.post('https://pnotifications.sofmen.com/backend/ios_notification.php', formData).then(res => {
    	if(res.data.status){
    		this.setState({
		      success_msg: res.data.msg
		    });
    	}else{
    		this.setState({
		      error_msg: res.data.msg
		    });
    	}
    });

    
    //window.location = '/';
  }

  onSubmitApn(e) {
    e.preventDefault();
    this.setState({
      error_msg: '',
      success_msg: ''
    });
    
    axios.defaults.headers.common = {
      "Access-Control-Allow-Origin": "*",
	  'Content-Type' : 'multipart/form-data',
	}
    const formData = new FormData();

    formData.append('device_token',this.state.device_token);
    formData.append('pem_file',this.state.pem_file);
    formData.append('pem_text',this.state.pem_text);
    formData.append('bundle_id',this.state.bundle_id);
    formData.append('message',this.state.message);
    formData.append('submitAPN',true);

    axios.post('https://pnotifications.sofmen.com/backend/ios_notification.php', formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(res => {
    	if(res.data.status){
    		this.setState({
		      success_msg: res.data.msg
		    });
    	}else{
    		this.setState({
		      error_msg: res.data.msg
		    });
    	}
    });

    //window.location = '/';
  }

  render() {
    return (
      <div>
        <section class="push_notification">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<h2>Push Notifications</h2>
					<span id="success_div">{this.state.success_msg}</span>
					<span id="error_div">{this.state.error_msg}</span>
					<ul class="nav nav-tabs">
					  	<li class="nav-item">
					  		<a class="nav-link active" data-toggle="tab" href="#APNS_push">APNS Push Notifications</a>
					  	</li>
					  	<li class="nav-item">
					    	<a class="nav-link" data-toggle="tab" href="#FCM_push">FCM Push Notifications</a>
					  	</li>
					</ul>

					<div class="tab-content">
					  	<div class="tab-pane active" id="APNS_push">
					  		<form onSubmit={this.onSubmitApn} class="push_notification_form" method="post" enctype="multipart/form-data">
					  			<div class="row">
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>PEM Certificates<span class="estarik">*</span></label>
							  				<div class='file-input'>
											  	<input type='file' name="pem_file" required="required" onChange={this.onChangePemFile} />
											  	<span class='button'>Choose file</span>
											  	<span class='label' data-js-label>No file selected</span>
											</div>
							  			</div> 
					  				</div>
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>Device Token(s)<span class="estarik">*</span></label>
							  				<input type="text" name="device_token" class="form-control"  placeholder="Device Token" required="required" value={this.state.device_token} onChange={this.onChangeDeviceToken} />
							  			</div>
					  				</div>
					  				<div class="col-md-6">
					  					<div class="form-group">
							  				<label>Password<span class="estarik">*</span></label>
							  				<input type="password" name="pem_text" class="form-control" required="required" placeholder="Password" value={this.state.pem_text} onChange={this.onChangePemText} />
							  			</div>
					  				</div>
					  				<div class="col-md-6">
					  					<div class="form-group">
							  				<label>Bundle id</label>
							  				<input type="text" class="form-control" placeholder="Enter Bundle ID" required="required" name="bundle_id" value={this.state.bundle_id} onChange={this.onChangeBundleId} />
							  			</div>
					  				</div>
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>Message<span class="estarik">*</span></label>
							  				<textarea rows="4" class="form-control" required="required" placeholder="Enter Push Notification Message" name="message" value={this.state.message} onChange={this.onChangeMessage}></textarea >
							  			</div>
					  				</div>
					  				<div class="col-md-12">
					  					<button type="submit" name="submitApn" class="btn custom_btn">Submit</button>
					  				</div>
					  			</div>
					  		</form>
					  	</div>
					  	<div class="tab-pane fade" id="FCM_push">
					  		<form onSubmit={this.onSubmit} class="push_notification_form" method="post" >
					  			<div class="row">
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>API Key<span class="estarik">*</span></label>
							  				<input type="text" class="form-control" placeholder="Enter API KEY" name="server_key" required="required" value={this.state.api_key} onChange={this.onChangeApiKey} />
							  			</div> 
					  				</div>
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>Device Token(s)<span class="estarik">*</span></label>
							  				<input type="text" class="form-control" placeholder="Device Token" name="device_token" required="required" value={this.state.device_token} onChange={this.onChangeDeviceToken} />
							  			</div>
					  				</div>
					  				<div class="col-md-12">
					  					<div class="form-group">
							  				<label>Message<span class="estarik">*</span></label>
							  				<textarea rows="4" class="form-control" placeholder="Enter Push Notification Message" name="message" required="required" value={this.state.message} onChange={this.onChangeMessage}></textarea>
							  			</div>
					  				</div>
					  				<div class="col-md-12">
					  					<button type="submit" name="submitFCM" class="btn custom_btn">Submit</button>
					  				</div>
					  			</div>
					  		</form>
					  	</div>
					</div>
				</div>
			</div>
		</div>
	</section>
      </div>
    )
  }
}
